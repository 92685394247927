






































import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
@Component({
  metaInfo() {
    const meta: MetaInfo = {
      // Children can override the title.
      title: "Approach Us",
      // Define meta tags here.
      meta: [
        {
          httpEquiv: "Content-Type",
          content: "text/html; charset=utf-8",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "This is the method to our madness, the process of how we bring your vision to life. Step 1 - Meet - This is where we get to know you and collectively define the goals for the project. We believe great collaborations result in great work. Step 2 - Plan - We start with a high level overview of what is needed to achieve the goals set for the project. Step 3 - Design & Develop - This is where our team starts to get their hands dirty. Firstly by designing your project according to your specifications, then moving forward to the development stage. Step 4 - Demo's & Check-In's - Throughout the design and development process, we will meet regulary to give you feedback and show you the progress on your project. These check-in sessions can be anything from 5 minutes for feedback or thourough discussions about what the state of project is. Step 5 - Iteration - We repeat the steps to ensure we have filfulled your vison and reach the goals set. Step 6 - Launch - Upon satisfactory the project gets signed off and released. 7 - Support - This step is important and often forgotten. We will always keep supporting your goals. From minor updates to any technical difficulty, we've got you covered!",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.ottohjattorneys.co.za/approach",
        },
      ],
    };
    return meta;
  },
})
export default class Approach extends Vue {
  steps = [
    {
      color: "green--text text--darken-3",
      timelineItem: "green darken-3",
      heading: "Meet",
      text: "This is where we get to know you and collectively define the goals for the project. We believe great collaborations result in great work. ",
      step: "Step 1",
    },
    {
      color: "grey--text text--darken-2",
      timelineItem: "grey darken-2",
      heading: "Plan",
      text: "We start with a high level overview of what is needed to achieve the goals set for the project.",
      step: "Step 2",
    },
    {
      color: "",
      timelineItem: "black",
      heading: "Design & Develop",
      text: "This is where our team starts to get their hands dirty. Firstly by designing your project according to your specifications, then moving forward to the development stage.",
      step: "Step 3",
    },
    {
      color: "green--text text--darken-3",
      timelineItem: "green darken-3",
      heading: "Demo's & Check-In's",
      text: "Throughout the design and development process, we will meet regulary to give you feedback and show you the progress on your project. These check-in sessions can be anything from 5 minutes for feedback or thourough discussions about what the state of project is.",
      step: "Step 4",
    },
    {
      color: "grey--text text--darken-2",
      timelineItem: "grey darken-2",
      heading: "Iteration",
      text: "We repeat the steps to ensure we have filfulled your vison and reach the goals set.",
      step: "Step 5",
    },
    {
      color: "",
      timelineItem: "black",
      heading: "Launch",
      text: "Upon satisfactory the project gets signed off and released. ",
      step: "Step 6",
    },
    {
      color: "green--text text--darken-3",
      timelineItem: "green darken-3",
      heading: "Support",
      text: "This step is important and often forgotten. We will always keep supporting your goals. From minor updates to any technical difficulty, we've got you covered!",
      step: "Step 7",
    },
  ];
}
