import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Landing from "@/views/Landing.vue";
import About from "@/views/About.vue";
import Services from "@/views/Services.vue";
import Approach from "@/views/Approach.vue";
import Contact from "@/views/Contact.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: {
      name: "landing",
    },
  },
  {
    path: "/landing",
    name: "landing",
    component: Landing,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/services",
    name: "services",
    component: Services,
  },
  {
    path: "/approach",
    name: "approach",
    component: Approach,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
export default router;
