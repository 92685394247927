
















































































































































import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
@Component({
  metaInfo() {
    const meta: MetaInfo = {
      // Children can override the title.
      title: "Services",
      meta: [
        {
          httpEquiv: "Content-Type",
          content: "text/html; charset=utf-8",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "At OTTO HJ Attorneys, our passion is to connect with our clients and to walk the extra mile.Family law We assist clients in family related matters, for example, defending a client in divorce proceedings, child custody battles or child support claims.Drafting of different types of contracts We assist individual clients or businesses with problems relating to contracts and provide advice from a legal standpoint on whether or not to agree to and sign a contract or we assist clients to figure out how to combat a contract that they have already signed.Corporate work for companies Advising executives against business decisions that could lead to legal action and representing the company when legal action is taken against them. We also use our expertise to create and edit corporate contracts such as shareholder agreements. Insolvencies We provide clients with advice on their financial situation, if they are eligible for applying for insolvency, what type of insolvency will best suit their needs and provide information on how to file for insolvency. Wills Drafting of wills and administration of deceased estates. Section 65A(1) of the Magistrate Court Act 90 of 1993 proceedings We assist clients in submitting relevant documentation and to plead their cases.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://ottohjattorneys.co.za/#/services",
        },
      ],
    };
    return meta;
  },
})
export default class Services extends Vue {}
