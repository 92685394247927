










































































































import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
@Component({
  metaInfo() {
    const meta: MetaInfo = {
      // Children can override the title.
      title: "Contact Us",
      meta: [
        {
          httpEquiv: "Content-Type",
          content: "text/html; charset=utf-8",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "Let's Talk. Whether you're curious about any of our services or want to get started on a project, we're ready to answer the call. Do you want to get in touch with us directly? Give us a ring (+27 84 876 0883). Have any questions or want to set up a meeting? Send us an email and we'll get back to you (ottohj@ottohjattorneys.co.za)",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.ottohjattorneys.co.za/contact-us",
        },
      ],
    };
    return meta;
  },
})
export default class Contact extends Vue {}
