


















































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { goToPage } from "@/scripts/navigation";
import { MetaInfo } from "vue-meta";

@Component({
  metaInfo() {
    const meta: MetaInfo = {
      // Children can override the title.
      title: "Landing",
      meta: [
        {
          httpEquiv: "Content-Type",
          content: "text/html; charset=utf-8",
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "description",
          content:
            "Our firm’s overall goal is to provide legal services that are timely, effective, efficient and of high quality to all clients and to constantly improve our services in using existing knowledge, expertise and resources within our firm to deliver services to all clients. We believe that clients will support us rather than our competition because our legal services are streamlined to be accessible at a very reasonable cost and to assist those cannot get free legal aid in accordance with section 35 of the Legal Practice Act 28 of 2014.",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://ottohjattorneys.co.za",
        },
      ],
    };
    return meta;
  },
})
export default class Landing extends Vue {
  goToAbout(): void {
    goToPage("about");
  }
  goToServices(): void {
    goToPage("services");
  }
  goToContact(): void {
    goToPage("contact");
  }
}
